import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../views/login/AuthPage.vue";
//import Home from '../views/Home.vue'
import store from "../store";
import OrganizationAdmin from "@/views/organization-admin/router";
import Staff from "@/views/staff/router";
import GosOrganization from "@/views/gos-organization/router";
// gos - organization;
Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:loginType*",
    name: "login",
    component: Auth,
  },
  OrganizationAdmin,
  Staff,
  GosOrganization,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    if (store.getters.getAccessToken) {
      next(store.getters.getCurrentRole);
    } else {
      next("/login/" + store.getters.getCurrentRole);
    }
  } else if (to.name == "login") {
    if (store.getters.getAccessToken) {
      next(store.getters.getCurrentRole);
    } else {
      next();
    }
  } else {
    if (store.getters.getAccessToken) {
      next();
    } else {
      store.dispatch("logout");
    }
  }
  // next();
});

export default router;
