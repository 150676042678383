<template>
  <v-app>
    <v-app-bar app flat>
      <v-container class="py-0 fill-height">
        <v-btn
          :href="'https://gos.zerek.edus.kz/' + selectedLanguage + index"
          v-for="(link, index) in links"
          :key="index"
          text
          class="header-menu-item white--text"
        >
          {{ link }}
        </v-btn>
        <v-btn
          :href="'https://portal.zerek.edus.kz/'"
          text
          class="header-menu-item white--text"
        >
          {{ $t("Частные организации") }}
        </v-btn>

        <v-responsive class="shrink" style="margin-left: auto">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                depressed
                class="white--text transparent"
              >
                {{ languages[selectedLanguage] }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                v-for="(item, index) in languages"
                :key="index"
                @click.stop="changeLanguage(index)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <v-container>
		<br /><br />
		
      <div class="text-center">
        <h3 class="login-title my-16">{{ $t("Вход в кабинет") }}</h3>
      </div>

      <v-row justify="center">
        <v-col>
          <v-tabs v-model="activeTab" background-color="transparent" centered>
            <v-tab @click="changeLoginType('gos-organization')">{{
              $t("Гос организации")
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row class="align-center" justify="center">
        <v-col class="login-form-block" lg="9" md="9">
          <div class="form-wrap pa-10">
            <v-row justify="center">
              <v-col>
                <div class="login-btn-group">
                  <!--  -->
                  <template
                    v-if="
                      loginType == 'gos-organization' ||
                      loginType == 'admin2' ||
                      loginType == 'staff2'
                    "
                  >
                    <v-btn
                      @click="changeLoginType('gos-organization')"
                      :class="{ active: loginType == 'gos-organization' }"
                      >{{
                        $t("Руководитель")
                      }}</v-btn
                    >
                    <v-btn
                      @click="changeLoginType('admin2')"
                      :class="{ active: loginType == 'admin2' }"
                      >{{ $t("Администратор") }}
                    </v-btn>

                    <v-btn
                      @click="changeLoginType('staff2')"
                      :class="{ active: loginType == 'staff2' }"
                      >{{ $t("Сотрудник") }}
                    </v-btn>
                  </template>

                  <template
                    v-else-if="
                      loginType == 'organization' ||
                      loginType == 'staff' ||
                      loginType == 'admin'
                    "
                  >
                    <v-btn
                      @click="changeLoginType('organization')"
                      :class="{ active: loginType == 'organization' }"
                      >{{ $t("Руководитель") }}
                    </v-btn>
                    <v-btn
                      @click="changeLoginType('admin')"
                      :class="{ active: loginType == 'admin' }"
                      >{{ $t("Администратор") }}
                    </v-btn>
                    <v-btn
                      @click="changeLoginType('staff')"
                      :class="{ active: loginType == 'staff' }"
                      >{{ $t("Сотрудник") }}
                    </v-btn>
                  </template>

                </div>
              </v-col>
            </v-row>
            <v-form
              class="login-form"
              ref="form"
              @submit.prevent="handleSubmit"
            >
              <!-- <v-file-input
                color="indigo lighten-1"
                class="mb-4"
                :label="$t('Выберите файл')"
                accept=".p12"
                :rules="[(v) => !!v || $t('Выберите ЭЦП')]"
                @change="toBase64"
              ></v-file-input>
              <v-text-field
                color="indigo lighten-1"
                :label="$t('Пароль')"
                prepend-icon="mdi-key"
                v-model="ecpPassword"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || $t('Введите пароль')]"
                @click:append="showPassword = !showPassword"
              ></v-text-field> -->

              <v-flex class="d-flex justify-center">
                <v-btn
                  height="50px"
                  class="login-btn"
                  type="submit"
                  :loading="loading"
                >
                  {{ $t("Выберите ЭЦП") }}
                </v-btn>
              </v-flex>
            </v-form>
            <div v-if="error" class="error-message">{{ error }}</div>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="otherAccountsDialog" max-width="500" persistent>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("Выберите организацию") }}
            <v-spacer></v-spacer>
            <v-btn icon small @click="closeAccountDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-list three-line>
              <v-list-item
                v-for="(account, accountKey) in otherAccounts"
                :key="accountKey"
                @click="selectAccount(account)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    account.user.branch_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><b>{{ account.user.organization_name }}</b>
                    {{ account.user.full_address }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    
  </v-app>
</template>

<script>
export default {
  created() {
    // TODO FIX IT!!!!
    let langIndex = location.hash.search(/\?lang/);
    if (langIndex != -1) {
      let lang = location.hash.substring(langIndex + 6, langIndex + 8);
      location.hash = location.hash.replace("?lang=" + lang, "");
      this.changeLanguage(lang);
    }
  },
  mounted() {
    if (this.$route.params.loginType) {
      this.changeLoginType(this.$route.params.loginType, false);
    } else {
      this.changeLoginType("gos-organization");
    }
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.language;
    },
  },

  data() {
    return {
      error: null,
      socket: null,
      response: '',
      showPassword: false,
      ecpFileData: "",
      ecpPassword: "",
      emailData: '', 
      loading: false,
      loginType: "",
      languages: {
        kk: "Қазақша",
        ru: "Русский",
      },
      links: {
        "/": this.$t("Главная"),
      },
      activeTab: null,
      otherAccounts: [],
      otherAccountsDialog: false,
      userInfo: null,
    };
  },
  methods: {
    connectWebSocket() {
      return new Promise((resolve, reject) => {
        this.socket = new WebSocket('wss://127.0.0.1:13579/');

        this.socket.onopen = () => {
          console.log('WebSocket connection opened');
          resolve();
        };

        this.socket.onmessage = (event) => {
          const eventData = event['data'];
          const parsedData = JSON.parse(eventData);
          console.log(parsedData['data']);
          if(parsedData['code'] == 200){
            this.handleWebSocketMessage(parsedData);
          }else{
            console.log("No data");
          }
        };

        this.socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          this.error = 'У Вас NCALayer не запущен';
          reject(error);
        };

        this.socket.onclose = (event) => {
          console.log('WebSocket connection closed:', event);
        };
      });
    },

    changeLoginType(type, push = true) {
      if (this.loginType != type) {
        this.$refs.form.reset();
        this.ecpFileData = "";
        this.ecpPassword = "";
        this.loginType = type;
        if (
          this.loginType == "gos-organization" ||
          this.loginType == "admin2" ||
          this.loginType == "staff2"
        ) {
          this.activeTab = 4;
        }
        if (push) {
          this.$router.push({ path: "/login/" + type });
        }
      }
    },
    async handleSubmit() {
      if (this.loginType == "staff2") {
        this.loginType = "staff";
      } else if (this.loginType == "admin2") {
        this.loginType = "admin";
      }

      if (this.loginType === "superadmin") {
        // const NCALayerData = { AuthData: parsedMessage };
        // await this.sendResponseToBackend(NCALayerData);
      } else {
          try {
          await this.connectWebSocket();
          
          if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            // Формируем JSON запрос
            const request = {
              module: "kz.gov.pki.knca.commonUtils",
              method: "getKeyInfo",
              args: ["PKCS12"]
            };
            
            // Сериализуем запрос в строку JSON
            const requestString = JSON.stringify(request);
            // console.log('Sending message:', requestString);
            
            // Отправляем JSON через WebSocket
            this.socket.send(requestString);
            } else {
              console.log('WebSocket is not open');
            }
          } catch (error) {
            console.error('Error while sending request:', error);
          }
      }
      

// if (this.loginType === "superadmin") {
//   postData.email = this.emailData;
//   console.log("Superadmin login data:", this.emailData);
// } else {
//   postData.p12 = this.ecpFileData;
//   console.log("Other login data:", this.ecpFileData);
// }

this.routerlink = this.loginType === "superadmin" ? "/auth" : "/ecp-auth";
// console.log("Routerlink:", this.routerlink);

// if (this.$refs.form.validate()) {
//   this.loading = true;
//   try {
//     const response = await this.$http.post(this.selectedLanguage + "/" + this.loginType + this.routerlink, postData);
//     this.loading = false;

//     if (this.loginType === "parent") {
//       localStorage.removeItem("parentData");
//     }

//     if (this.loginType == "admin") {
//       if (response.data.other_accounts.length == 1) {
//         this.login(response.data.token, response.data.user);
//       } else {
//         this.userInfo = response.data.user;
//         this.otherAccounts = response.data.other_accounts;
//         this.otherAccountsDialog = true;
//       }
//     } else {
//       this.login(response.data.token, response.data.user);
//     }
//   } catch (error) {
//     this.loading = false;
//     console.error("Error during login:", error);
//   }
// } else {
//   console.error("Form validation failed");
// }

// Для отладки, выводим postData в консоль
// console.log("PostData:", postData);

    },

    async handleWebSocketMessage(parsedData) {
      try {
        const NCALayerData = { AuthData: parsedData };
        await this.sendResponseToBackend(NCALayerData);
      } catch (error) {
        console.error('Error while sending response to backend:', error);
      }
    },

    async sendResponseToBackend(responseData) {
      this.loading = true;
      try {
        // Отправляем полученный ответ на бэкенд
        const response = await this.$http.post(this.selectedLanguage + "/" + this.loginType + this.routerlink, responseData);
        this.loading = false;

        if (this.loginType === "parent") {
          localStorage.removeItem("parentData");
        }

        if (this.loginType == "admin") {
          if (response.data.other_accounts.length == 1) {
            this.login(response.data.token, response.data.user);
          } else {
            this.userInfo = response.data.user;
            this.otherAccounts = response.data.other_accounts;
            this.otherAccountsDialog = true;
          }
        } else {
          this.login(response.data.token, response.data.user);
        }
      } catch (error) {
        this.loading = false;
        console.error("Error during login:", error);  
      }
    },

    login(token, user, otherAccounts = []) {
      this.$store
        .dispatch("login", {
          loginType: this.loginType,
          token: token,
          user: user,
          otherAccounts: otherAccounts,
        })
        .then(() => {
          // if(this.$router.history.current.query.url && this.$router.history.current.query.url.indexOf('/'+this.loginType) === 0) {
          // 	this.$router.push({ path: this.$router.history.current.query.url }); // TODO
          // } else {
          // 	this.$router.push({ path: '/'+this.loginType });
          // }
          this.$router.push({ path: "/" + this.loginType });
        });
    },

    closeAccountDialog() {
      this.userInfo = null;
      this.otherAccountsDialog = false;
      this.otherAccounts = [];
    },
    selectAccount(account) {
      this.login(account.token, this.userInfo, this.otherAccounts);
      this.otherAccounts = [];
      this.otherAccountsDialog = false;
    },
    changeLanguage(lang) {
      this.$store.commit("setLanguage", lang);
      location.reload();
    },
    toBase64(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          this.ecpFileData = encoded;
        };
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
#app {
  background-color: #f6f6f6;
}
.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}
.v-toolbar {
  background-color: #0066ff !important;
}
.alert-link svg {
            margin-left: 3px;
			margin-bottom: 3px;
            vertical-align: middle;
            fill: #ffffff;
            width: 25px;
            height: 25px;
}
.alert-link {
	background-color: #25D366;
	border: 2px solid #ffffff;
	padding: 8px;
	border-radius: 7px;
	color: #ffffff;
    text-decoration: none;
}
.alert-link:hover {
    border-color: #25D366;
}
.alert-container {
	background-color: #ffbf00cc;
	border-radius: 10px;
	text-align: center;
	padding: 20px;
	margin: 20px 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.alert-title {
  margin: 10px 0;
  color: #fff;
  font-weight: bold;
}
.alert-text {
  margin: 10px 0;
  color: #fff;
}
.login-title {
  font-size: 36px;
}
.v-btn.login-btn {
  background-color: #0066ff;
  box-shadow: 0px 0px 30px rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  color: #fff;
  margin-top: 25px;
  padding: 26px 60px;
  text-transform: initial;
  font-size: 16px;
  font-weight: 400;
}
.form-wrap {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #ccc;
}
.login-form-block {
}
.login-btn-group {
  text-align: center;
  margin-bottom: 30px;
}
.login-btn-group button.v-btn {
  border-radius: 10px;
  text-transform: initial;
  background-color: #e2eeff;
  box-shadow: none;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 15px;
  padding: 22px 35px;
}
.login-btn-group button.v-btn + button.v-btn {
  margin-left: 10px;
}
.login-btn-group button.active {
  background-color: #35445a;
  box-shadow: 0px 0px 30px rgba(0, 102, 255, 0.25);
  color: #fff;
}

.header-menu-item {
  text-transform: initial;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.header-menu-item.active {
  background-color: rgb(255 255 255 / 20%);
  box-shadow: 0px 0px 10px rgb(14 14 14 / 20%);
  border-radius: 10px;
}
.header-menu-item {
  margin-right: 5px;
}
.container {
  max-width: 1185px;
}
</style>
